body {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
}

#front,
#left,
#right,
#back,
#top,
#bottom {
  width: 16.6666666667%;
  height: 100%;
}

#blank {
  display: none;
}

.cesium-viewer-bottom {
  display: none !important;
}
