body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
}

#front, #left, #right, #back, #top, #bottom {
  width: 16.6667%;
  height: 100%;
}

#blank {
  display: none;
}

.cesium-viewer-bottom {
  display: none !important;
}

.cesium-svgPath-svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cesium-button {
  color: #edffff;
  fill: #edffff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #303336;
  border: 1px solid #444;
  border-radius: 4px;
  margin: 2px 3px;
  padding: 5px 12px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.cesium-button:focus {
  color: #fff;
  fill: #fff;
  border-color: #ea4;
  outline: none;
}

.cesium-button:hover {
  color: #fff;
  fill: #fff;
  background: #48b;
  border-color: #aef;
  box-shadow: 0 0 8px #fff;
}

.cesium-button:active {
  color: #000;
  fill: #000;
  background: #adf;
  border-color: #fff;
  box-shadow: 0 0 8px #fff;
}

.cesium-button-disabled, .cesium-button-disabled:active, .cesium-button-disabled:focus, .cesium-button-disabled:hover, .cesium-button:disabled {
  color: #646464;
  fill: #646464;
  box-shadow: none;
  cursor: default;
  background: #303336;
  border-color: #444;
}

.cesium-button option {
  color: #eee;
  background-color: #000;
}

.cesium-button option:disabled {
  color: #777;
}

.cesium-button input, .cesium-button label {
  cursor: pointer;
}

.cesium-button input {
  vertical-align: sub;
}

.cesium-toolbar-button {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  z-index: 0;
  border-radius: 14%;
  padding: 0;
}

.cesium-performanceDisplay-defaultContainer {
  text-align: right;
  position: absolute;
  top: 50px;
  right: 10px;
}

.cesium-performanceDisplay {
  background-color: #282828b3;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 7px;
  font: bold 12px sans-serif;
}

.cesium-performanceDisplay-fps {
  color: #e52;
}

.cesium-performanceDisplay-throttled {
  color: #a42;
}

.cesium-performanceDisplay-ms {
  color: #de3;
}

.cesium-animation-theme {
  visibility: hidden;
  z-index: -100;
  display: block;
  position: absolute;
}

.cesium-animation-themeNormal {
  color: #222;
}

.cesium-animation-themeHover {
  color: #4488b0;
}

.cesium-animation-themeSelect {
  color: #242;
}

.cesium-animation-themeDisabled {
  color: #333;
}

.cesium-animation-themeKnob {
  color: #222;
}

.cesium-animation-themePointer {
  color: #2e2;
}

.cesium-animation-themeSwoosh {
  color: #8ac;
}

.cesium-animation-themeSwooshHover {
  color: #aef;
}

.cesium-animation-svgText {
  fill: #edffff;
  text-anchor: middle;
  font-family: sans-serif;
  font-size: 15px;
}

.cesium-animation-blank {
  fill: #000;
  fill-opacity: .01;
  stroke: none;
}

.cesium-animation-rectButton {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.cesium-animation-rectButton .cesium-animation-buttonGlow {
  fill: #fff;
  stroke: none;
  display: none;
}

.cesium-animation-rectButton:hover .cesium-animation-buttonGlow {
  display: block;
}

.cesium-animation-rectButton .cesium-animation-buttonPath {
  fill: #edffff;
}

.cesium-animation-rectButton .cesium-animation-buttonMain {
  stroke: #444;
  stroke-width: 1.2px;
}

.cesium-animation-rectButton:hover .cesium-animation-buttonMain {
  stroke: #aef;
}

.cesium-animation-rectButton:active .cesium-animation-buttonMain {
  fill: #abd6ff;
}

.cesium-animation-buttonDisabled {
  -webkit-user-select: none;
  user-select: none;
}

.cesium-animation-buttonDisabled .cesium-animation-buttonMain {
  stroke: #555;
}

.cesium-animation-buttonDisabled .cesium-animation-buttonPath {
  fill: #818181;
}

.cesium-animation-buttonDisabled .cesium-animation-buttonGlow {
  display: none;
}

.cesium-animation-buttonToggled .cesium-animation-buttonGlow {
  fill: #2e2;
  display: block;
}

.cesium-animation-buttonToggled .cesium-animation-buttonMain {
  stroke: #2e2;
}

.cesium-animation-buttonToggled:hover .cesium-animation-buttonGlow {
  fill: #fff;
}

.cesium-animation-buttonToggled:hover .cesium-animation-buttonMain {
  stroke: #2e2;
}

.cesium-animation-shuttleRingG, .cesium-animation-shuttleRingPointer, .cesium-animation-shuttleRingPausePointer {
  cursor: pointer;
}

.cesium-animation-shuttleRingBack {
  fill: #181818;
  fill-opacity: .8;
  stroke: #333;
  stroke-width: 1.2px;
}

.cesium-animation-shuttleRingSwoosh line {
  stroke: #8ac;
  stroke-width: 3px;
  stroke-opacity: .2;
  stroke-linecap: round;
}

.cesium-animation-knobOuter {
  cursor: pointer;
  stroke: #444;
  stroke-width: 1.2px;
}

.cesium-animation-knobInner {
  cursor: pointer;
}

.cesium-baseLayerPicker-selected {
  width: 100%;
  height: 100%;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
}

.cesium-baseLayerPicker-dropDown {
  box-sizing: content-box;
  width: 320px;
  max-height: 500px;
  -webkit-user-select: none;
  user-select: none;
  visibility: hidden;
  opacity: 0;
  background-color: #262626bf;
  border: 1px solid #444;
  border-radius: 10px;
  margin-top: 5px;
  padding: 6px;
  transition: visibility 0s .2s, opacity .2s ease-in, transform .2s ease-in;
  display: block;
  position: absolute;
  top: auto;
  right: 0;
  overflow: auto;
  transform: translate(0, -20%);
}

.cesium-baseLayerPicker-dropDown-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s ease-out, transform .2s ease-out;
  transform: translate(0);
}

.cesium-baseLayerPicker-sectionTitle {
  text-align: left;
  color: #edffff;
  margin-bottom: 4px;
  font-family: sans-serif;
  font-size: 16pt;
  display: block;
}

.cesium-baseLayerPicker-choices {
  margin-bottom: 5px;
}

.cesium-baseLayerPicker-categoryTitle {
  color: #edffff;
  font-size: 11pt;
}

.cesium-baseLayerPicker-choices {
  border: 1px solid #888;
  border-radius: 5px;
  padding: 5px 0;
  display: block;
}

.cesium-baseLayerPicker-item {
  vertical-align: top;
  width: 64px;
  text-align: center;
  cursor: pointer;
  margin: 2px 5px;
  display: inline-block;
}

.cesium-baseLayerPicker-itemLabel {
  text-align: center;
  vertical-align: middle;
  color: #edffff;
  cursor: pointer;
  word-wrap: break-word;
  font-family: sans-serif;
  font-size: 8pt;
  display: block;
}

.cesium-baseLayerPicker-item:focus .cesium-baseLayerPicker-itemLabel, .cesium-baseLayerPicker-item:hover .cesium-baseLayerPicker-itemLabel {
  text-decoration: underline;
}

.cesium-baseLayerPicker-itemIcon {
  width: inherit;
  height: auto;
  color: #edffff;
  cursor: pointer;
  box-sizing: border-box;
  background-size: 100% 100%;
  border: 1px solid #444;
  border-radius: 9px;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.cesium-baseLayerPicker-item:hover .cesium-baseLayerPicker-itemIcon {
  border-color: #fff;
  box-shadow: 0 0 8px #fff, 0 0 8px #fff;
}

.cesium-baseLayerPicker-selectedItem .cesium-baseLayerPicker-itemLabel {
  color: #bdecf8;
}

.cesium-baseLayerPicker-selectedItem .cesium-baseLayerPicker-itemIcon {
  border: 4px double #bdecf8;
}

.cesium-widget {
  position: relative;
}

.cesium-widget, .cesium-widget canvas {
  width: 100%;
  height: 100%;
  touch-action: none;
}

.cesium-widget-credits {
  color: #fff;
  text-shadow: 0 0 2px #000;
  padding-right: 5px;
  font-size: 10px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cesium-widget-credits a, .cesium-widget-credits a:visited {
  color: #fff;
}

.cesium-widget-errorPanel {
  text-align: center;
  z-index: 99999;
  background: #000000b3;
  position: absolute;
  inset: 0;
}

.cesium-widget-errorPanel:before {
  vertical-align: middle;
  height: 100%;
  content: "";
  display: inline-block;
}

.cesium-widget-errorPanel-content {
  width: 75%;
  max-width: 500px;
  text-align: left;
  vertical-align: middle;
  color: #510c00;
  background-color: #f0d9d5;
  border: 1px solid #510c00;
  border-radius: 7px;
  font-size: 14px;
  display: inline-block;
}

.cesium-widget-errorPanel-content.expanded {
  max-width: 75%;
}

.cesium-widget-errorPanel-header {
  background: #d69d93;
  border-bottom: 2px solid #510c00;
  border-radius: 3px 3px 0 0;
  padding: 15px;
  font-family: Open Sans, Verdana, Geneva, sans-serif;
  font-size: 18px;
}

.cesium-widget-errorPanel-scroll {
  white-space: pre-wrap;
  margin: 10px 0 20px;
  padding: 0 15px;
  font-family: Open Sans, Verdana, Geneva, sans-serif;
  overflow: auto;
}

.cesium-widget-errorPanel-buttonPanel {
  text-align: right;
  margin: 10px 0 20px;
  padding: 0 15px;
}

.cesium-widget-errorPanel-buttonPanel button {
  color: #202020;
  background: #d69d93;
  border-color: #510c00;
  margin: 0;
}

.cesium-widget-errorPanel-buttonPanel button:focus, .cesium-widget-errorPanel-buttonPanel button:hover {
  color: #510c00;
  background: #f0d9d5;
  border-color: #510c00;
}

.cesium-widget-errorPanel-buttonPanel button:active {
  color: #510c00;
  background: #b17b72;
  border-color: #510c00;
}

.cesium-widget-errorPanel-more-details {
  cursor: pointer;
  text-decoration: underline;
}

.cesium-widget-errorPanel-more-details:hover {
  color: #2b0700;
}

.cesium-cesiumInspector {
  color: #edffff;
  -webkit-user-select: none;
  user-select: none;
  background: #303336cc;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 4px 12px;
  transition: width .25s ease-in-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.cesium-cesiumInspector-button {
  text-align: center;
  font-size: 11pt;
}

.cesium-cesiumInspector-visible .cesium-cesiumInspector-button {
  border-bottom: 1px solid #aaa;
  padding-bottom: 3px;
}

.cesium-cesiumInspector input:enabled, .cesium-cesiumInspector-button {
  cursor: pointer;
}

.cesium-cesiumInspector-visible {
  width: 185px;
  height: auto;
}

.cesium-cesiumInspector-hidden {
  width: 122px;
  height: 17px;
}

.cesium-cesiumInspector-sectionContent {
  max-height: 500px;
}

.cesium-cesiumInspector-section-collapsed .cesium-cesiumInspector-sectionContent {
  max-height: 0;
  overflow: hidden;
  padding: 0 !important;
}

.cesium-cesiumInspector-dropDown {
  width: 185px;
  margin: 5px 0;
  font-family: sans-serif;
  font-size: 10pt;
}

.cesium-cesiumInspector-frustumStatistics {
  background-color: #505050bf;
  padding: 5px;
}

.cesium-cesiumInspector-pickButton {
  color: #edffff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000004d;
  border: 1px solid #444;
  border-radius: 5px;
  margin: 0 auto;
  padding: 3px 7px;
}

.cesium-cesiumInspector-pickButton:focus {
  outline: none;
}

.cesium-cesiumInspector-pickButton:active, .cesium-cesiumInspector-pickButtonHighlight {
  color: #000;
  background: #adf;
  border-color: #fff;
  box-shadow: 0 0 8px #fff;
}

.cesium-cesiumInspector-center {
  text-align: center;
}

.cesium-cesiumInspector-sectionHeader {
  cursor: pointer;
  margin: 0;
  font-size: 10pt;
  font-weight: 700;
}

.cesium-cesiumInspector-pickSection {
  border: 1px solid #aaa;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 3px;
}

.cesium-cesiumInspector-sectionContent {
  margin-bottom: 10px;
  transition: max-height .25s;
}

.cesium-cesiumInspector-tileText {
  border-bottom: 1px solid #aaa;
  padding-bottom: 10px;
}

.cesium-cesiumInspector-relativeText {
  padding-top: 10px;
}

.cesium-cesiumInspector-sectionHeader:before {
  content: "-";
  width: 1ch;
  margin-right: 5px;
  display: inline-block;
}

.cesium-cesiumInspector-section-collapsed .cesium-cesiumInspector-sectionHeader:before {
  content: "+";
}

ul.cesium-cesiumInspector-statistics {
  margin: 0;
  padding-top: 3px;
  padding-bottom: 3px;
}

ul.cesium-cesiumInspector-statistics + ul.cesium-cesiumInspector-statistics {
  border-top: 1px solid #aaa;
}

.cesium-cesiumInspector-slider {
  margin-top: 5px;
}

.cesium-cesiumInspector-slider input[type="number"] {
  text-align: left;
  color: #edffff;
  width: 100px;
  cursor: auto;
  background-color: #222;
  border: 1px solid #444;
  border-radius: 3px;
  outline: none;
  margin-left: 10px;
  padding: 1px;
}

.cesium-cesiumInspector-slider input[type="number"]::-webkit-inner-spin-button, .cesium-cesiumInspector-slider input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cesium-cesiumInspector-slider input[type="range"] {
  vertical-align: middle;
  margin-left: 5px;
}

.cesium-cesiumInspector-hide .cesium-cesiumInspector-styleEditor {
  display: none;
}

.cesium-cesiumInspector-styleEditor {
  background: #303336cc;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
}

.cesium-cesiumInspector-styleEditor textarea {
  width: 100%;
  height: 300px;
  color: #edffff;
  white-space: pre;
  overflow-wrap: normal;
  background: none;
  border: none;
  padding: 0;
  overflow-x: auto;
}

.cesium-3DTilesInspector {
  width: 300px;
  pointer-events: all;
}

.cesium-3DTilesInspector-statistics {
  font-size: 11px;
}

.cesium-3DTilesInspector div, .cesium-3DTilesInspector input[type="range"] {
  width: 100%;
  box-sizing: border-box;
}

.cesium-cesiumInspector-error {
  color: #ff9e9e;
  overflow: auto;
}

.cesium-3DTilesInspector .cesium-cesiumInspector-section {
  margin-top: 3px;
}

.cesium-3DTilesInspector .cesium-cesiumInspector-sectionHeader + .cesium-cesiumInspector-show {
  border-top: 1px solid #fff;
}

input.cesium-cesiumInspector-url {
  white-space: nowrap;
  color: #fff;
  height: 1em;
  width: 100%;
  background-color: #0000;
  border: none;
  outline: none;
  overflow-x: scroll;
  overflow-y: hidden;
}

.cesium-cesiumInspector .field-group {
  display: table;
}

.cesium-cesiumInspector .field-group > label {
  font-weight: 700;
  display: table-cell;
}

.cesium-cesiumInspector .field-group > .field {
  width: 100%;
  display: table-cell;
}

.cesium-button.cesium-fullscreenButton, .cesium-button.cesium-vrButton {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
  display: block;
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input {
  color: #fff;
  vertical-align: middle;
  width: 0;
  height: 32px;
  box-sizing: border-box;
  -webkit-appearance: none;
  background-color: #282828b3;
  border: 1px solid #444;
  border-radius: 0;
  margin: 0;
  padding: 0 32px 0 0;
  transition: width .25s ease-in-out, background-color .2s ease-in-out;
  display: inline-block;
}

.cesium-viewer-geocoderContainer:hover .cesium-geocoder-input {
  border-color: #aef;
  box-shadow: 0 0 8px #fff;
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input:focus {
  box-shadow: none;
  background-color: #0f0f0fe6;
  border-color: #ea4;
  outline: none;
}

.cesium-viewer-geocoderContainer .cesium-geocoder-input-wide, .cesium-viewer-geocoderContainer .cesium-geocoder-input:focus, .cesium-viewer-geocoderContainer:hover .cesium-geocoder-input {
  width: 250px;
  padding-left: 4px;
}

.cesium-viewer-geocoderContainer .search-results {
  color: #eee;
  opacity: .8;
  width: 100%;
  background-color: #000;
  position: absolute;
  overflow-y: auto;
}

.cesium-viewer-geocoderContainer .search-results ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cesium-viewer-geocoderContainer .search-results ul li {
  padding: 3px 10px;
  font-size: 14px;
}

.cesium-viewer-geocoderContainer .search-results ul li:hover {
  cursor: pointer;
}

.cesium-viewer-geocoderContainer .search-results ul li.active {
  background: #48b;
}

.cesium-geocoder-searchButton {
  cursor: pointer;
  width: 32px;
  height: 30px;
  vertical-align: middle;
  fill: #edffff;
  background-color: #303336;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 1px;
}

.cesium-geocoder-searchButton:hover {
  background-color: #48b;
}

.cesium-infoBox {
  width: 40%;
  max-width: 480px;
  color: #edffff;
  visibility: hidden;
  opacity: 0;
  background: #262626f2;
  border: 1px solid #444;
  border-right: none;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  transition: visibility 0s .2s, opacity .2s ease-in, transform .2s ease-in;
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  transform: translate(100%);
  box-shadow: 0 0 10px 1px #000;
}

.cesium-infoBox-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s ease-out, transform .2s ease-out;
  transform: translate(0);
}

.cesium-infoBox-title {
  height: 20px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: content-box;
  background: #545454;
  border-top-left-radius: 7px;
  padding: 5px 30px 5px 25px;
  display: block;
  overflow: hidden;
}

.cesium-infoBox-bodyless .cesium-infoBox-title {
  border-bottom-left-radius: 7px;
}

button.cesium-infoBox-camera {
  width: 22px;
  height: 22px;
  background: none;
  border-color: #0000;
  border-radius: 3px;
  margin: 0;
  padding: 0 5px;
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
}

button.cesium-infoBox-close {
  height: 20px;
  color: #edffff;
  background: none;
  border: none;
  border-radius: 2px;
  margin: 0;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 700;
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
}

button.cesium-infoBox-close:focus {
  background: #ee880070;
  outline: none;
}

button.cesium-infoBox-close:hover {
  color: #000;
  background: #888;
}

button.cesium-infoBox-close:active {
  color: #000;
  background: #a00;
}

.cesium-infoBox-bodyless .cesium-infoBox-iframe {
  display: none;
}

.cesium-infoBox-iframe {
  width: 100%;
  width: calc(100% - 2px);
  border: none;
}

span.cesium-sceneModePicker-wrapper {
  margin: 0 3px;
  display: inline-block;
  position: relative;
}

.cesium-sceneModePicker-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .25s linear;
}

.cesium-sceneModePicker-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .25s, opacity .25s linear;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-none {
  display: none;
}

.cesium-sceneModePicker-slide-svg {
  transition: left 2s;
  top: 0;
  left: 0;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-dropDown-icon {
  box-sizing: border-box;
  margin: 3px 0;
  padding: 0;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button2D, .cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button3D, .cesium-sceneModePicker-wrapper .cesium-sceneModePicker-buttonColumbusView {
  margin: 0 0 3px;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button3D .cesium-sceneModePicker-icon2D {
  left: 100%;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button3D .cesium-sceneModePicker-iconColumbusView {
  left: 200%;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-buttonColumbusView .cesium-sceneModePicker-icon3D {
  left: -200%;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-buttonColumbusView .cesium-sceneModePicker-icon2D, .cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button2D .cesium-sceneModePicker-icon3D {
  left: -100%;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-button2D .cesium-sceneModePicker-iconColumbusView {
  left: 100%;
}

.cesium-sceneModePicker-wrapper .cesium-sceneModePicker-selected {
  border-color: #2e2;
  box-shadow: 0 0 8px #fff, 0 0 8px #fff;
}

span.cesium-projectionPicker-wrapper {
  margin: 0 3px;
  display: inline-block;
  position: relative;
}

.cesium-projectionPicker-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .25s linear;
}

.cesium-projectionPicker-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .25s, opacity .25s linear;
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-none {
  display: none;
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-dropDown-icon {
  box-sizing: border-box;
  margin: 3px 0;
  padding: 0;
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonOrthographic, .cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonPerspective {
  margin: 0 0 3px;
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonPerspective .cesium-projectionPicker-iconOrthographic {
  left: 100%;
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-buttonOrthographic .cesium-projectionPicker-iconPerspective {
  left: -100%;
}

.cesium-projectionPicker-wrapper .cesium-projectionPicker-selected {
  border-color: #2e2;
  box-shadow: 0 0 8px #fff, 0 0 8px #fff;
}

.cesium-performance-watchdog-message-area {
  color: #000;
  background-color: #ff0;
  padding: 10px;
  position: relative;
}

.cesium-performance-watchdog-message {
  margin-right: 30px;
}

.cesium-performance-watchdog-message-dismiss {
  margin: 0 10px 0 0;
  position: absolute;
  right: 0;
}

.cesium-navigationHelpButton-wrapper {
  display: inline-block;
  position: relative;
}

.cesium-navigation-help {
  visibility: hidden;
  width: 250px;
  transform-origin: 234px -10px;
  border-radius: 10px;
  transition: visibility 0s .25s, transform .25s ease-in;
  position: absolute;
  top: 38px;
  right: 2px;
  transform: scale(.01);
}

.cesium-navigation-help-visible {
  visibility: visible;
  transition: transform .25s ease-out;
  transform: scale(1);
}

.cesium-navigation-help-instructions {
  background-color: #262626bf;
  border: 1px solid #444;
  border-radius: 0 0 10px 10px;
  padding-bottom: 5px;
}

.cesium-click-navigation-help {
  display: none;
}

.cesium-touch-navigation-help {
  padding-top: 5px;
  display: none;
}

.cesium-click-navigation-help-visible, .cesium-touch-navigation-help-visible {
  display: block;
}

.cesium-navigation-help-pan {
  color: #6cf;
  font-weight: 700;
}

.cesium-navigation-help-zoom {
  color: #65fd00;
  font-weight: 700;
}

.cesium-navigation-help-rotate {
  color: #ffd800;
  font-weight: 700;
}

.cesium-navigation-help-tilt {
  color: #d800d8;
  font-weight: 700;
}

.cesium-navigation-help-details {
  color: #fff;
}

.cesium-navigation-button {
  color: #fff;
  width: 50%;
  cursor: pointer;
  background-color: #0000;
  border-top: 1px solid #444;
  border-bottom: none;
  border-right: 1px solid #444;
  margin: 0;
}

.cesium-navigation-button-icon {
  vertical-align: middle;
  padding: 5px 1px;
}

.cesium-navigation-button:focus {
  outline: none;
}

.cesium-navigation-button-left {
  border-left: 1px solid #444;
  border-radius: 10px 0 0;
}

.cesium-navigation-button-right {
  border-left: none;
  border-radius: 0 10px 0 0;
}

.cesium-navigation-button-selected {
  background-color: #262626bf;
}

.cesium-navigation-button-unselected {
  background-color: #000000bf;
}

.cesium-navigation-button-unselected:hover {
  background-color: #4c4c4cbf;
}

.cesium-selection-wrapper {
  width: 160px;
  height: 160px;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s ease-in;
  position: absolute;
}

.cesium-selection-wrapper-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s ease-out;
}

.cesium-selection-wrapper svg {
  fill: #2e2;
  stroke: #000;
  stroke-width: 1.1px;
}

.cesium-timeline-main {
  border: 1px solid #888;
  position: relative;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.cesium-timeline-trackContainer {
  width: 100%;
  border-top: 1px solid #888;
  position: relative;
  top: 0;
  left: 0;
  overflow: auto;
}

.cesium-timeline-tracks {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cesium-timeline-needle {
  width: 1px;
  background: red;
  position: absolute;
  top: 1.7em;
  bottom: 0;
  left: 0;
}

.cesium-timeline-bar {
  cursor: pointer;
  width: 100%;
  height: 1.7em;
  background: linear-gradient(#747577cc 0, #3a4452cc 11%, #2e3238cc 46%, #353535cc 81% 100%);
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cesium-timeline-ruler {
  visibility: hidden;
  white-space: nowrap;
  z-index: -200;
  font-size: 80%;
}

.cesium-timeline-highlight {
  background: #08f;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cesium-timeline-ticLabel {
  white-space: nowrap;
  color: #eee;
  font-size: 80%;
  position: absolute;
  top: 0;
  left: 0;
}

.cesium-timeline-ticMain {
  width: 1px;
  height: 50%;
  background: #eee;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cesium-timeline-ticSub {
  width: 1px;
  height: 33%;
  background: #aaa;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cesium-timeline-ticTiny {
  width: 1px;
  height: 25%;
  background: #888;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cesium-timeline-icon16 {
  width: 16px;
  height: 16px;
  background-image: url("TimelineIcons.5dffe9bd.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
}

.cesium-viewer {
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  font-size: 16px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cesium-viewer-cesiumWidgetContainer {
  width: 100%;
  height: 100%;
}

.cesium-viewer-bottom {
  padding-right: 5px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cesium-viewer .cesium-widget-credits {
  color: #fff;
  text-shadow: 0 0 2px #000;
  padding-right: 0;
  font-size: 10px;
  display: inline;
  position: static;
  bottom: auto;
  left: auto;
}

.cesium-viewer-timelineContainer {
  height: 27px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 169px;
  right: 29px;
  overflow: hidden;
}

.cesium-viewer-animationContainer {
  width: 169px;
  height: 112px;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cesium-viewer-fullscreenContainer, .cesium-viewer-vrContainer {
  width: 29px;
  height: 29px;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.cesium-viewer-toolbar {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
}

.cesium-viewer-cesiumInspectorContainer {
  display: block;
  position: absolute;
  top: 50px;
  right: 10px;
}

.cesium-viewer-geocoderContainer {
  margin: 0 3px;
  display: inline-block;
  position: relative;
}

.cesium-viewer-cesium3DTilesInspectorContainer {
  max-height: calc(100% - 120px);
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 50px;
  right: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

/*# sourceMappingURL=index.49148762.css.map */
